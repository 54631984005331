import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const CancelTokenMessage = 'Request cancelled'

const findCatalogOffer = (accountingFirmId, catalogOfferId, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}

	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/catalog-offers/${catalogOfferId}`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: cancelToken
		})
	}
}

const listCatalogOffers = (accountingFirmId, params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}
	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/catalog-offers`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: params
		})
	}
}

const findCatalogService = (accountingFirmId, catalogServiceId, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}
	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/catalog-services/${catalogServiceId}`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token
		})
	}
}

const listCatalogServices = (accountingFirmId, params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}
	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/catalog-services`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: params
		})
	}
}

const listCategories = (accountingFirmId, params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}

	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/categories`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: params
		})
	}
}

const listCompanies = (params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}

	const newCancelToken = Configurator.CancelToken.source()
	const url = 'companies'

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: params
		})
	}
}

const createServiceRequest = (accountingFirmId, vendorId, data, params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}

	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/service-requests`

	return {
		cancelToken: newCancelToken,
		promise: axios.post(url, data, {
			cancelToken: newCancelToken.token,
			params: params
		})
	}
}

export default {
	findCatalogOffer,
	listCatalogOffers,

	findCatalogService,
	listCatalogServices,

	listCategories,

	listCompanies,

	createServiceRequest
}

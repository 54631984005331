import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	SERVICE_SELECT: 'service-select',
	SERVICE_UNSELECT: 'service-unselect',
	OFFER_SELECT: 'offer-select',
	OFFER_UNSELECT: 'offer-unselect'
})

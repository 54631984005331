<template>
	<router-view />
</template>

<script>
import ServiceRequestsModuleGuard from '@/mixins/ModulesGuards/ServiceRequests/ServiceRequestsModuleGuard'

export default {
	name: 'ServiceRequests',
	mixins: [ServiceRequestsModuleGuard],
	props: {
		filters: {
			default: () => ({
				categories: []
			}),
			required: true,
			type: Object
		}
	}
}
</script>

// ============================================================================
// ServiceRequestsService
// ----------------------
// ServiceRequests module related services
// ============================================================================

// -------
// Imports
// -------
import { router } from '@/router'

import API from '@/apis/ServiceRequestsApi'

import AppService from '@/services/AppService'

// ---------
// Internals
// ---------

// -------
// Exports
// -------
export default {
	setSearchedCategories: function (searchedCategories = []) {
		const currentRoute = router.history.current
		let query = {
			...currentRoute.query,
			categories: JSON.stringify(searchedCategories)
		}
		if (searchedCategories.length === 0) {
			delete query.categories
		}
		AppService.goTo({
			query: query
		})
	},
	findCatalogOffer: function (accountingFirmId, catalogOfferId, cancelToken) {
		const result = API.findCatalogOffer(accountingFirmId, catalogOfferId, cancelToken)
		result.promise = result.promise.then(res => res.data.data)
		return result
	},
	listCatalogOffers: function (accountingFirmId, filters, cancelToken) {
		let params = {}
		if (filters && filters.hasOwnProperty('categories')) {
			params.categories = filters.categories
		}
		const result = API.listCatalogOffers(accountingFirmId, params, cancelToken)
		result.promise = result.promise.then(res => res.data.data)
		return result
	},
	selectCatalogOffer: function (accountingFirmId, vendorId, offerId, other = {}) {
		const currentRoute = router.history.current
		AppService.goTo(
			Object.assign(
				{
					name: 'service-requests-catalog-offer-detail',
					params: Object.assign(currentRoute.params, {
						context: router.encryptContext(accountingFirmId, vendorId),
						offerId: offerId.toString(16)
					})
				},
				other
			)
		)
	},
	findCatalogService: function (accountingFirmId, catalogServiceId, cancelToken) {
		const result = API.findCatalogService(accountingFirmId, catalogServiceId, cancelToken)
		result.promise = result.promise.then(res => res.data.data)
		return result
	},
	listCatalogServices: function (accountingFirmId, filters, cancelToken) {
		let params = {}
		if (filters && filters.hasOwnProperty('categories')) {
			params.categories = filters.categories
		}
		let result = API.listCatalogServices(accountingFirmId, params, cancelToken)
		result.promise = result.promise.then(res => res.data.data)
		return result
	},
	selectCatalogService: function (accountingFirmId, vendorId, serviceId, other = {}) {
		const currentRoute = router.history.current
		AppService.goTo(
			Object.assign(
				{
					name: 'service-requests-catalog-service-detail',
					params: Object.assign(currentRoute.params, {
						context: router.encryptContext(accountingFirmId, vendorId),
						serviceId: serviceId.toString(16)
					})
				},
				other
			)
		)
	},
	listCategories: function (accountingFirmId, cancelToken) {
		let result = API.listCategories(accountingFirmId, cancelToken)
		result.promise = result.promise.then(res => res.data.data)
		return result
	},
	findCompany: function (siret, cancelToken) {
		return API.listCompanies({ siret }, cancelToken)
			.promise.then(res => res.data.data)
			.then(companies => {
				let result = companies[0]
				if (companies.length === 1) {
					result = companies[0]
				} else {
					let matchingCompany = companies.find(company => company.siret === siret || company.siret?.startsWith(siret))
					if (matchingCompany) {
						result = matchingCompany
					}
				}
				return result
			})
	},
	createServiceRequest: function (accountingFirmId, vendorId, data, cancelToken) {
		let result = API.createServiceRequest(accountingFirmId, vendorId, data, cancelToken)
		result.promise = result.promise.then(res => res.data.data)
		return result
	}
}
